/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.scss"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

gsap.registerPlugin(ScrollTrigger)

const themeAnimation = (dataAnimation, el, speed) => {
  switch (dataAnimation) {
    case "neo-background-2":
      gsap.fromTo(
        el,
        {
          //y: "0",
          boxShadow: "0px 0px 0px #b6b6b6, 0px 0px 0px #ffffff",

          //transform: "scale(.95)",
        },
        {
          //y: 0,
          boxShadow: "12px 12px 24px #b6b6b6, -12px -12px 24px #ffffff",
          //transform: "scale(1)",
          duration: 2,

          scrollTrigger: {
            trigger: el,
            start: "top 70%",
            //scrub: true,
          },
        }
      )
      break
    case "neo-background-1":
      gsap.fromTo(
        el,
        {
          y: "0",
          boxShadow: "0px 0px 0px #d2d2d2, 0px 0px 0px #ffffff",
          transform: "scale(.95)",
        },
        {
          y: 0,
          boxShadow: "20px 20px 60px #d2d2d2, -20px -20px 60px #ffffff",
          transform: "scale(.95)",
          duration: 3,

          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            // scrub: true,
          },
        }
      )
      break
    case "neo-background-btn--send":
      gsap.fromTo(
        el,
        {
          transform: "scale(.95)",
          boxShadow: "0px 0px 0px #009388, 0px 0px 0px #00ffff",
        },
        {
          transform: "scale(1)",
          boxShadow: "8px 8px 15px #009388, -8px -8px 15px #00ffff",
          transform: "scale(1)",
          duration: 2,
          delay: 1.5,
          scrollTrigger: {
            trigger: el,
            start: "top 100%",
          },
        }
      )
      break

    case "neo-background-btn":
      gsap.fromTo(
        el,
        {
          transform: "scale(.95)",
          boxShadow: "0px 0px 0px #cbcbcb, 0px 0px 0px #ffffff",
        },
        {
          boxShadow: "12px 12px 24px #cbcbcb, -12px -12px 24px #ffffff",
          transform: "scale(1)",
          duration: 2,
          delay: 1.5,
          scrollTrigger: {
            trigger: el,
          },
        }
      )
      break
    case "paralax":
      gsap.to(el, {
        scrollTrigger: {
          scrub: true,
        },
        y: (i, target) => -ScrollTrigger.maxScroll(window) * speed,
        ease: "none",
      })
      break
    case "form":
      gsap.fromTo(
        el,
        {
          boxShadow: "0px 0px 0px #009388, 0px 0px 0px #00ffff",
          // transform: "scale(.95)",
        },
        {
          boxShadow: "inset 8px 8px 15px #009388, inset -8px -8px 15px #00ffff",
          //transform: "scale(1)",
          duration: 2,
          scrollTrigger: {
            trigger: el,

            //scrub: true,
          },
        }
      )
      break
    case "neo-background-2--technologie":
      gsap.fromTo(
        el,
        {
          y: "0",
          boxShadow: "0px 0px 0px #d2d2d2, 0px 0px 0px #ffffff",
          transform: "scale(.95)",
        },
        {
          y: 0,
          boxShadow: "20px 20px 60px #d2d2d2, -20px -20px 60px #ffffff",
          transform: "scale(1)",

          duration: 1,

          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            //scrub: true,
          },
        }
      )

      break
    case "fade":
      gsap.fromTo(
        el,
        {
          y: "0",
          opacity: "0",
        },
        {
          y: 0,
          opacity: "1",
          duration: 1,
          delay: ".2",

          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            //scrub: true,
          },
        }
      )
      break

    case "subtitle":
      gsap.fromTo(
        el,
        {
          x: "-100",
          opacity: "0",
        },
        {
          x: 0,
          opacity: "1",
          duration: 0.5,
          delay: "2",
        }
      )
      break
    case "title":
      gsap.fromTo(
        el,
        {
          x: "0",
          opacity: "0",
        },
        {
          x: 0,
          opacity: "1",
          duration: 2,
          delay: ".5",
        }
      )
      break
  }
}

const Layout = ({ children }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".animate")

    elements.forEach(el => {
      let dataAnimation = el.dataset.animation
      let speed = el.dataset.speed

      themeAnimation(dataAnimation, el, speed)
    })
  })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      {children}

      <Footer></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
