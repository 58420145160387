import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

import PropTypes from "prop-types"
import React from "react"

import classes from "./header.module.scss"
import Language from "./language"

const Header = () => {
  const intl = useIntl()
  return (
    <header>
      <div className={classes.logobox}>
        <Link to="/">
          <img
            className={classes.logo}
            src={require("../images/pawelstyczen_logo.svg")}
            alt="logo"
          ></img>
        </Link>
        <p>Paweł Styczeń</p>
      </div>

      <nav>
        <ul>
          <li>
            <Language />
          </li>
          <li className={classes.link}>
            <Link
              to="/about"
              activeStyle={{
                fontWeight: 700,
              }}
              partiallyActive={true}
            >
              {intl.formatMessage({ id: "about--link" })}
            </Link>
          </li>
          <li className={classes.link}>
            <Link
              to="/projects"
              activeStyle={{
                fontWeight: 700,
              }}
              partiallyActive={true}
            >
              <FormattedMessage id="projects--link" />
            </Link>
          </li>
          <li className={classes.link}>
            <Link
              to="/contact"
              activeStyle={{
                fontWeight: 700,
              }}
              partiallyActive={true}
            >
              <FormattedMessage id="contact--link" />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
