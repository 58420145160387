import React from "react"

import classes from "./footer.module.scss"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <p className={classes.footer__text}>
        <FormattedMessage id="footerText" />
      </p>

      <div className={classes.footer__linkbox}>
        <Link to="/cookies">Cookies</Link>
        <Link to="/polityka">Polityka prywatności</Link>
        <Link to="/resume">Hire me</Link>
      </div>
    </footer>
  )
}

export default Footer
